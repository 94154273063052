<script setup>
const props = defineProps({
    field: {
        type: Object,
        default: () => ({}),
    },
    namespace: {
        type: Array,
        default: () => [],
    },
    namespaceSuffix: {
        type: Array,
        default: () => [],
    },
});

const { getId, attrs } = useFormieField(props.field, props.namespace, props.namespaceSuffix);

const maxlength = computed(() => {
    if (props.field.limit && props.field.limitType === 'characters') {
        return props.field.limitAmount;
    }
    return null;
});

function wordCheck(e) {
    if (!props.field.limit || props.field.limitType !== 'words') {
        return;
    }

    setTimeout(() => {
        const { value } = e.target;
        const wordCount = value.split(/\S+/).length - 1;
        const regex = new RegExp(`^\\s*\\S+(?:\\s+\\S+){0,${props.field.limitAmount - 1}}`);

        if (wordCount >= props.field.limitAmount) {
            // eslint-disable-next-line prefer-destructuring
            e.target.value = value.match(regex)[0];
        }
    }, 1);
}
</script>

<template>
    <div class="group col-span-2 lg:col-span-1">
        <FormieLabel
            :id="getId()"
            :field="field"
            position="above-input"
        />

        <FormieInstructions :field="field" position="above-input" />

        <input
            v-bind="attrs()"
            :maxlength="maxlength"
            type="text"
            class="peer w-full border-b bg-lune px-4 pb-3 pt-7 text-base text-charbon outline-none placeholder:text-charbon focus:border-carmin"
            v-on:keydown="wordCheck"
        >

        <FormieInstructions :field="field" position="below-input" />

        <FormieLabel
            :id="getId()"
            :field="field"
            position="below-input"
        />
    </div>
</template>
